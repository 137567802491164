var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"applicants d-flex align-center overflow-x-hidden"},[(_vm.matching_applicants.length > 0)?[_vm._l((_vm.matching_applicants),function(applicant,index){return [(applicant.upload_response.profile)?_c('v-tooltip',{key:index,attrs:{"bottom":"","attach":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"avatar",attrs:{"color":!applicant.avatar_uri
                ? _vm.get_color(applicant.upload_response.profile)
                : null,"size":"65"},on:{"click":function($event){return _vm.navigate(
                'candidate',
                applicant.candidate_id,
                applicant.upload_response_id
              )}}},'v-avatar',attrs,false),on),[(applicant.avatar_uri)?_c('v-img',{attrs:{"src":applicant.avatar_uri,"alt":_vm.get_name(applicant.upload_response.profile)}}):_c('span',{staticClass:"white--text base-body-text"},[_vm._v(" "+_vm._s(_vm.get_initials(applicant.upload_response.profile))+" ")])],1)]}}],null,true)},[_c('v-layout',{staticClass:"my-1",attrs:{"column":""}},[_c('p',{staticClass:"mb-1 font-weight-bold base-body-text white--text"},[_c('span',{staticClass:"text-capitalize d-block"},[_vm._v(_vm._s(_vm.get_name(applicant.upload_response.profile)))]),_c('span',{staticClass:"text-capitalize d-block"},[_vm._v(_vm._s(_vm.get_designation(applicant.upload_response.profile)))])]),_c('p',{staticClass:"mb-0 base-body-text white--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.candidates.view.matching-score"))+": "+_vm._s(_vm.format_overall_score(applicant.score))+"% ")])])],1):_vm._e()]}),_c('v-avatar',{staticClass:"avatar",attrs:{"color":"light-bg","size":"65"},on:{"click":function($event){return _vm.navigate('applicants', _vm.job_id, '0')}}},[_c('p',{staticClass:"mb-0 base-body-text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.applicants.length)+"+ ")])])]:[_c('p',{staticClass:"mb-0 bas fetch_name,e-body-text no-applicants"},[_vm._v(" Applicants not found ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }