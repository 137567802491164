




























































































import Vue from "vue";
import { JOB_SHORT_URL } from "@/store/modules/recruiter/constants";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "ShareItem",
  data() {
    return {
      url: undefined,
      facebook: require("@/assets/icons/facebook.svg"),
      twitter: require("@/assets/icons/twitter.svg"),
      linkedin: require("@/assets/icons/linkedin.svg"),
      whatsapp: require("@/assets/icons/whatsapp.svg"),
      display_link_copied_snackbar: false
    };
  },
  props: {
    title: {
      required: true,
      type: String
    },
    jobId: {
      required: true,
      type: Number
    },
    jobTitle: {
      required: true,
      type: String
    },
    jobDescription: {
      required: true,
      type: String
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  mounted: function () {
    this.generateShortURL();
  },
  methods: {
    ...mapActions("recruiter", {
      fetch_job_short_url: JOB_SHORT_URL
    }),
    /**
     * Generates a short URL for the job using its ID and a base job URL.
     * The resulting URL is stored in the `this.url` property.
     */
    async generateShortURL() {
      const obj = {
        job_id: this.jobId,
        job_url: `https://${this.hostname()}/public/job`
      };
      const response = await this.fetch_job_short_url(obj);
      this.url = response.url ? response.url : undefined;
    },
    /**
     * Creates a shareable text snippet combining the job title and a truncated job description.
     * @returns {string} A string containing the job title and the beginning of the job description.
     */
    shareText() {
      return `${this.jobTitle} ${this.jobDescription.substring(0, 50)}... `;
    },
    /**
     * Opens a new window to share the job URL on a given base URL (social platform).
     * @param {string} baseURL The base URL of the social platform for sharing.
     */
    openShareWindow(baseURL: string) {
      window.open(`${baseURL}${this.url}`, "_blank");
    },
    /**
     * Shares the job on Facebook using a predefined URL pattern.
     */
    shareFacebook() {
      this.openShareWindow(
        `https://www.facebook.com/sharer/sharer.php?t=${this.shareText()}&u=`
      );
    },
    /**
     * Shares the job on Twitter using a predefined URL pattern.
     */
    shareTwitter() {
      this.openShareWindow(
        `https://twitter.com/intent/tweet?text=${this.shareText()}&url=`
      );
    },
    /**
     * Shares the job on LinkedIn using a predefined URL pattern.
     */
    shareLinkedIn() {
      this.openShareWindow(
        `https://www.linkedin.com/sharing/share-offsite/?title=${this.shareText()}&url=`
      );
    },
    /**
     * Shares the job on WhatsApp using a predefined URL pattern.
     */
    shareWhatsApp() {
      this.openShareWindow(
        `https://api.whatsapp.com/send?text=${this.shareText()}`
      );
    },
    /**
     * Copies the job's short URL to the clipboard and triggers a UI indication that the link was copied.
     */
    async copyLink() {
      await navigator.clipboard.writeText(this.url ?? "");
      this.display_link_copied_snackbar = true;
    },
    /**
     * Retrieves the hostname from the current window's location.
     * @returns {string} The hostname of the current window location.
     */
    hostname() {
      return window.location.hostname;
    }
  }
});
