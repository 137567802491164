











































































import Vue, { PropType } from "vue";
import { get_random_color_hash } from "@/utils/colors";
import {
  clean_string,
  format_overall_score,
  get_name_initials
} from "@/utils/global";
import { ListingApplicants } from "@/interfaces/recruiter/jobs/job_listing/listing_applicants";
import { fetch_latest_work_history } from "@/utils/cv_parser";
import router from "@/router";
import { mapMutations } from "vuex";
import {
  CANDIDATE_VIEW_PAYLOAD,
  VIEW_CANDIDATES_DYNAMICALLY
} from "@/store/modules/recruiter/constants";
import { SELECTED_USERS, USER_INFO } from "@/store/modules/common/constants";
import {
  SearchedCandidate,
  SearchedCandidateProfile
} from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "ListingApplicants",
  data(): ListingApplicants {
    return {
      matching_applicants: []
    };
  },
  props: {
    applicants: {
      type: Array as PropType<SearchedCandidate[]>,
      required: true
    },
    job_id: {
      type: Number,
      required: true
    },
    job_title: {
      type: String,
      required: true
    }
  },
  watch: {
    breakpoints() {
      this.applicant_resetting();
    }
  },
  mounted() {
    this.applicant_resetting();
  },
  computed: {
    breakpoints() {
      return this.$vuetify.breakpoint.width;
    }
  },
  methods: {
    ...mapMutations("recruiter", {
      set_candidates_view_dynamically: VIEW_CANDIDATES_DYNAMICALLY,
      set_candidate_view_payload: CANDIDATE_VIEW_PAYLOAD
    }),
    ...mapMutations("common", {
      user_info_filter: USER_INFO,
      selected_users_filter: SELECTED_USERS
    }),
    format_overall_score,
    fetch_latest_work_history,
    get_color(applicant: SearchedCandidateProfile): number | string {
      const details_exist = Object.keys(applicant.personal_details).length > 0;
      if (!details_exist) return "0";
      const name = Object.values(applicant.personal_details.name).join(" ");
      return get_random_color_hash(name);
    },
    /**
     * Navigate to job applicants/candidate page
     * @param where{string} => applicants/candidate
     * @param id{number} => id of job/candidate
     * @param upload_response_id
     */
    async navigate(where: string, id: number, upload_response_id: string) {
      const to = clean_string(where);
      switch (to) {
        // Navigate to candidates page
        case "applicants":
          this.user_info_filter([]);
          this.selected_users_filter([]);
          this.set_candidates_view_dynamically({
            active: true,
            loading: true,
            job_id: id,
            job_title: this.job_title,
            skill: "",
            user_ids: []
          });
          await router.push("/recruiter/candidates");
          break;
        case "candidate":
          sessionStorage.setItem("job", this.job_id.toString());
          sessionStorage.setItem("user", id.toString());
          sessionStorage.setItem("upload", upload_response_id);
          await this.$router.push("/recruiter/candidates/profile");
          break;
      }
    },
    get_initials(applicant: SearchedCandidateProfile): string {
      return get_name_initials(this.get_name(applicant));
    },
    get_name(applicant: SearchedCandidateProfile): string {
      const details_exist = Object.keys(applicant.personal_details).length > 0;
      if (!details_exist) return "";
      return Object.values(applicant.personal_details.name).join(" ");
    },
    get_designation(applicant: SearchedCandidateProfile): string {
      if (
        applicant.work_history_detailed &&
        Object.values(applicant.work_history_detailed).length > 0
      )
        return Object.values(applicant.work_history_detailed)[0].job_title;
      return "";
    },
    /**
     * Slice applicants based on different screen sizes
     */
    applicant_resetting() {
      if (this.$vuetify.breakpoint.xs) {
        this.matching_applicants =
          this.applicants.length > 3
            ? this.applicants.slice(0, 3)
            : this.applicants;
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        this.matching_applicants =
          this.applicants.length > 4
            ? this.applicants.slice(0, 4)
            : this.applicants;
      } else if (
        this.$vuetify.breakpoint.mdAndUp &&
        this.$vuetify.breakpoint.lgAndDown
      )
        this.matching_applicants =
          this.applicants.length > 5
            ? this.applicants.slice(0, 5)
            : this.applicants;
      else
        this.matching_applicants =
          this.applicants.length > 7
            ? this.applicants.slice(0, 7)
            : this.applicants;
    }
  }
});
