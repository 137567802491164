














import Vue from "vue";
import { ListingTHead } from "@/interfaces/recruiter/jobs/job_listing/listing_thead";
import { mapGetters } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "ListingTHead",
  data(): ListingTHead {
    return {
      table_headers: []
    };
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  created() {
    this.set_table_header();
  },
  watch: {
    get_site_direction() {
      this.set_table_header();
    }
  },
  methods: {
    set_table_header() {
      this.table_headers = [
        {
          title: this.$t("recruiter.job-listing.thead.col-1")
        },
        {
          title: this.$t("recruiter.job-listing.thead.col-2")
        },
        {
          title: this.$t("recruiter.job-listing.thead.col-3")
        }
      ];
    }
  }
});
