var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-8 listing-body"},[_c('div',{staticClass:"t-body"},[(_vm.get_posted_jobs_loading)?[_c('v-row',{staticClass:"base-card pa-0 data-row my-5 my-md-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-3 px-md-4 py-md-5",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)]:(
        !_vm.get_posted_jobs_loading && _vm.get_posted_jobs.results.length <= 0
      )?[_c('v-row',{staticClass:"base-card t-body-no-jobs"},[_c('v-col',{staticClass:"d-flex align-center justify-center flex-column pa-16",attrs:{"cols":"12"}},[_c('DataNotFound',{attrs:{"title":_vm.get_no_data_msg()}}),_c('v-btn',{staticClass:"my-10 base-submit-button t-body-no-jobs-post-job-btn",attrs:{"to":"jobs/post-job"}},[_vm._v(" "+_vm._s(_vm.$t("nav-sidebar.post-job"))+" ")])],1)],1)]:_vm._l((_vm.get_posted_jobs.results),function(job,index){return _c('v-row',{key:index,staticClass:"base-card pa-0 data-row my-5 my-md-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-3 px-md-4 py-md-5 data-col",attrs:{"cols":"12","md":"4"}},[_c('v-row',{staticClass:"inner-row"},[_c('v-col',{staticClass:"header d-flex align-center d-md-none",attrs:{"cols":"4","md":"0"}},[_c('p',{staticClass:"mb-0 base-body-text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.job-listing.thead.col-1"))+" ")])]),_c('v-col',{attrs:{"cols":"8","md":"12"}},[_c('p',{staticClass:"mb-0 base-body-text font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(job.job_title))+" ")])])],1)],1),_c('v-col',{staticClass:"pa-3 px-md-4 py-md-0 data-col",attrs:{"cols":"12","md":"4"}},[_c('v-row',{staticClass:"inner-row"},[_c('v-col',{staticClass:"header d-flex align-center d-md-none",attrs:{"cols":"4","md":"0"}},[_c('p',{staticClass:"mb-0 base-body-text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.job-listing.thead.col-2"))+" ")])]),_c('v-col',{attrs:{"cols":"8","md":"12"}},[(job.candidates_loading)?[_c('v-skeleton-loader',{staticClass:"d-flex",attrs:{"type":"avatar@6"}})]:(
                  !job.candidates_loading &&
                  job.matching_candidates.length <= 0
                )?[_c('p',{staticClass:"base-body-text mb-0 no-applicants"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.job-listing.no-candidates"))+" ")])]:[_c('ListingApplicants',{attrs:{"job_id":job.jid,"job_title":job.job_title,"applicants":job.matching_candidates}})]],2)],1)],1),_c('v-col',{staticClass:"pa-3 px-md-4 py-md-5 data-col",attrs:{"cols":"12","md":"4"}},[_c('v-row',{staticClass:"inner-row"},[_c('v-col',{staticClass:"header d-flex align-center d-md-none",attrs:{"cols":"4","md":"0"}},[_c('p',{staticClass:"mb-0 base-body-text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.job-listing.thead.col-3"))+" ")])]),_c('v-col',{attrs:{"cols":"8","md":"12"}},[_c('ListingActions',{attrs:{"job":job},on:{"edit_job":_vm.activate_edit_job_mode,"view_job":_vm.activate_view_job_mode,"job_removed":_vm.job_removed}})],1)],1)],1)],1)})],2),_c('div',{staticClass:"t-footer"},[_c('v-row',{staticClass:"mt-16",attrs:{"align":"center","justify":"center"}},[(_vm.get_posted_jobs_loading)?[_c('v-skeleton-loader',{staticClass:"d-flex align-center mx-4 pagination-loader",attrs:{"tile":true,"type":"avatar@4"}})]:(!_vm.get_posted_jobs_loading && _vm.get_posted_jobs.total > 10)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"pagination-wrapper",attrs:{"color":"primary","length":Math.ceil(_vm.get_posted_jobs.total / 10),"total-visible":6,"prev-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                ? 'mdi-arrow-left'
                : 'mdi-arrow-right',"next-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                ? 'mdi-arrow-right'
                : 'mdi-arrow-left'},on:{"input":_vm.extract_jobs},model:{value:(_vm.get_posted_jobs.pagination),callback:function ($$v) {_vm.$set(_vm.get_posted_jobs, "pagination", $$v)},expression:"get_posted_jobs.pagination"}})],1)]:_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }